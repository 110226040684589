import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import CreateForm from "../../../components/CreateForm/CompanyCreate";
import { getCompanies } from "../../Dashboard/actions";
import { clearValues, createCompany } from "./actions";
import { toast } from "react-toastify";
import * as api from "services/api";

function Create({ history }) {
	const dispatch = useDispatch();

	const { loading, errors } = useSelector(s => s.companyCreate);
	const { token } = useSelector(state => state.login);
	const company_create = useSelector(s => s.form.company_create);

	const [salers, setSalers] = useState([]);
	const [accountManagers, setAccountManagers] = useState([]);
	const [connectionTypesData, setConnectionTypesData] = useState([]);
	const [digisacAttendants, setDigisacAttendants] = useState([]);

	const handleFindSalerList = userName => {
		let filterStr = "&filters[business.businessName]=suamei";

		if (userName) filterStr += `&filters[providerUser.name]=${userName}`;

		dispatch(
			getCompanies(0, 10, filterStr, "createdAt", true, false, (err, model) => {
				if (err)
					alert(
						"Não foi possível carregar os dados, erro: " + typeof err ===
							"string"
							? err
							: JSON.stringify(err)
					);

				setSalers(model);
			})
		);
	};

	const handleSelectSaler = () => {};

	const handleFindAccountManagerList = userName => {
		let filterStr = "&filters[business.businessName]=suamei";

		if (userName) filterStr += `&filters[providerUser.name]=${userName}`;

		dispatch(
			getCompanies(0, 10, filterStr, "createdAt", true, false, (err, model) => {
				if (err)
					alert(
						"Não foi possível carregar os dados, erro: " + typeof err ===
							"string"
							? err
							: JSON.stringify(err)
					);

				setAccountManagers(model);
			})
		);
	};

	const handleSelectAccountManager = () => {};

	const fetchConnectionTypes = async () => {
		const response = await api.sendGet(`/digisacTemplate/templateConnection`, {
			Authorization: "Bearer " + token
		});

		if (!response) {
			console.log("Erro ao buscar tipos de conexão");
			return;
		}

		const result = await response.json();

		if (result.isValid) {
			setConnectionTypesData(
				result.model.map(connection => {
					return {
						value: connection,
						label: connection
					};
				})
			);
		}
	};

	const fetchDigisacAttendants = async () => {
		try {
			const response = await api.sendGet("/digisacAttendants", {
				Authorization: "Bearer " + token
			});

			const result = await response.json();

			if (result.isValid) {
				setDigisacAttendants(
					result.model.map(attendant => {
						return {
							value: attendant._id,
							label: attendant.name
						};
					})
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		dispatch(clearValues());
		fetchConnectionTypes();
		fetchDigisacAttendants();
	}, []);

	const onSubmit = data => {
		dispatch(
			createCompany(
				{
					cnpj: data.cnpj.replace(/[^0-9]/g, ""),
					isHired: data.isHired,
					connectionType: data.connectionType.value,
					hasMessageAutomationBlocked: data.hasMessageAutomationBlocked,
					onboardData: {
						purchasedProviders: data.purchasedProviders
					},
					dit: data.dit.value,
					pipefyId: data.pipefyId,
					...(data.isHired && {
						onboardAttendantId: data.onboardAttendants.value,
						ongoingAttendantId: data.ongoingAttendants.value
					}),
					lifeInsurance: data.lifeInsurance.value
				},
				async (err, model) => {
					if (err && err.default) {
						toast.error(JSON.stringify(err.default));
					} else if (model && model.pipefyId) {
						try {
							await api.sendPatch(
								`/pipefy/pipefy-hirer-information?companyId=${model._id}&pipefyId=${model.pipefyId}`,
								{},
								{
									Authorization: "Bearer " + token
								}
							);
						} catch (error) {
							console.log(error);
						}
					}

					history.push("/companies/report-all");
				}
			)
		);
	};

	const initialValues = {
		isHired: false,
		cnpj: "",
		connectionType: {
			value: "geral",
			label: "geral"
		},
		hasMessageAutomationBlocked: true,
		dit: "",
		lifeInsurance: ""
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<div className="card__title">
							<h4 className="bold-text">Cadastrar nova empresa</h4>
						</div>
						<CardBody>
							<CreateForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								errors={errors}
								isInvalid={company_create && !!company_create.syncErrors}
								connectionTypesData={connectionTypesData}
								digisacAttendants={digisacAttendants}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}

export default Create;
